import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LinearProjects = () => (
  <Layout>
    <SEO title="Linear Projects" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Linear Projects</h1>
        <p>
          Roads and highways are frequent users of linear rights-of-way. For
          this reason, Departments of Transportation often insist that you prove
          or provide your prior rights inside the scope of a new project. In
          some states, by having prior rights inside the linear right-of-way,
          the local Department of Transportation responsible for buidling the
          new project will be obligated to pay for the relocation of any and all
          assets that interfere with their new plans. In the reverse, should you
          not possess prior rights, you may have to be prepared to pay all of
          the associated fiscal responsibilites tied to relocating your asset to
          a new location.{" "}
        </p>
        <p>
          Public or Private entities that find themselves in this situation may
          recognize a lack of expertise in this niche field and therefore find
          the need to hire competent consultative support for their endeavors.
          When such a need arises, please reach out to us at Gray Hawk Land
          Solutions so that we may provide you with accurate and professioal
          insights into the property rights you already have. Let our
          experienced lawyers and right-of-way professionals protect your
          interests and potentially save you significat resources.
        </p>
        <p>
          Gray Hawk will provide you with a professsional & robust report
          outlining your case to the appropriate Department of Transportation.
          The final report will consist of a legal brief outlaying the rights
          you own with all of the pertinent supporting documentation. Our GIS
          deparment will create and provide all relevant maps needed to show
          your right-of-way in a geospacial format allowing the reviewer to
          visually understand the situation.
        </p>
      </div>
    </div>
  </Layout>
)

export default LinearProjects
